import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import Cta from '../components/Cta';


const services = [
    {
      title: 'Web Development',
      description:
        'We specialize in creating responsive and user-friendly websites tailored to meet your business needs. Our team utilizes the latest technologies to build scalable and innovative web solutions.',
      icon: 'fas fa-laptop-code', // Font Awesome class for an icon
    },
    {
      title: 'Mobile App Development',
      description:
        'Transform your ideas into powerful mobile applications. Our experts develop custom mobile apps for iOS and Android, focusing on performance, security, and an intuitive user experience.',
      icon: 'fas fa-mobile-alt', // Font Awesome class for an icon
    },
    {
      title: 'Domain and Hosting',
      description:
        'Get reliable domain registration and hosting services. We offer secure and high-performance hosting solutions with 24/7 support, ensuring your website stays online and accessible.',
      icon: 'fas fa-globe', // Font Awesome class for an icon
    },
    {
      title: 'General IT Consulting Services',
      description:
        'Benefit from our IT expertise. We provide consultations to help you streamline your IT infrastructure, improve security measures, and optimize technology for business growth.',
      icon: 'fas fa-cogs', // Font Awesome class for an icon
    },
  ];

const Contact = () => {
    useDocTitle('MLD | Molad e Konsult - Send us a message')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type':  'multipart/form-data'
            }
        })
        .then(function (response) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            clearInput()
            //handle success
            Notiflix.Report.success(
                'Success',
                response.data.message,
                'Okay',
            );
        })
        .catch(function (error) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            //handle error
            const { response } = error;
            if(response.status === 500) {
                Notiflix.Report.failure(
                    'An error occurred',
                    response.data.message,
                    'Okay',
                );
            }
            if(response.data.errors !== null) {
                setErrors(response.data.errors)
            }
            
        });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200">
            <div className="bg-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
              <div className="max-w-5xl mx-auto">
                <h1 className="text-4xl font-bold mb-12 text-center">Our Services</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                  {services.map((service, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg p-6 flex flex-col items-center shadow-md transition duration-300 transform hover:scale-105"
                    >
                      <i className={`text-4xl mb-4 ${service.icon}`}></i>
                      <h2 className="text-xl font-semibold mb-2">{service.title}</h2>
                      <p className="text-gray-700 text-center">{service.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            </div>
            <Cta/>
            <Footer />
        </>


    )
}

export default Contact;