import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link, useLocation } from 'react-router-dom';

const NavLinks = () => {
    const location = useLocation();

  // Function to determine if a link is active
  const isActive = (pathname) => {
    return location.pathname === pathname ? 'text-orange-700 font-bold' : 'text-gray-500';
  };

    return (
        <>
            <HashLink className={`px-4 font-extrabold ${isActive('/about')} hover:text-orange-700`} smooth to="/about">
                About
            </HashLink>
            <HashLink className={`px-4 font-extrabold ${isActive('/services')} hover:text-orange-700`} smooth to="/services">
                Services
            </HashLink>
            <HashLink className={`px-4 font-extrabold ${isActive('/portfolio')} hover:text-orange-700`} to="/portfolio">
                Portfolio
            </HashLink>
            <HashLink className={`px-4 font-extrabold ${isActive('/#')} hover:text-orange-700`} to="/portfolio">
                Team
            </HashLink>
            <HashLink className={`px-4 font-extrabold ${isActive('/contact')} hover:text-orange-700`} to="/contact">
                Contact Us
            </HashLink>
            <Link className="text-white bg-orange-700 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo">
                Demo our products
            </Link>
        </>
    )
}

export default NavLinks;