import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import img from '../images/Web-developer.svg';
import Notiflix from 'notiflix';
import Cta from '../components/Cta';

const Contact = () => {
    useDocTitle('MLD | Molad e Konsult - Send us a message');

    return (
        <>
            <NavBar />
            <div className="bg-gray-100 min-h-screen flex items-center justify-center">
                <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
                    <div className="mb-4 md:mb-0 md:flex items-center justify-center">
                        {/* <div className="mr-8">
                            <img
                                src={img}
                                alt="Company Logo"
                                className="rounded-lg shadow-lg"
                            />
                        </div> */}
                        <div>
                            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-800">
                                About Us
                            </h1>
                            <p className="text-gray-700 leading-relaxed text-lg md:text-xl">
                                MLD Konsult is an Information Communication Technology( ICT) company with
                                expertise in the design, development, and deployment of bespoke software
                                applications used to automate business tasks, operations, routines, and
                                processes. MLD has professionals with vast experience in the automation of
                                various administrative processes at all levels of educational institutions to
                                connect administrators, faculty, parents, and students 24/7 from any location
                                and device.
                            </p>
                            <p className="text-gray-700 leading-relaxed text-lg md:text-xl mt-4">
                                Our team has worked together for almost two decades and is directly
                                responsible for the evolution of the portal management system of some world-
                                class universities in Nigeria. The expertise of the team in the deployment of
                                solutions for Management Information Systems in Universities began with the
                                deployment of the Microsoft Access Database Engine. Today, the team
                                deploys an enterprise system comprising modules that provide managers of
                                educational institutions with real-time raw and analyzed data, covering the
                                entire spectra of their operations, for management decisions accessible
                                anywhere from all available ICT devices.
                            </p>
                            <p className="text-gray-700 leading-relaxed text-lg md:text-xl mt-4">
                                MLD Konsult is sensitive to and mindful of data security and privacy
                                regulations and laws in Nigeria. Our team consents to global best practices
                                regarding the collection, handling, and protection of our Client’s data and the
                                rights of the data subjects against unauthorized access, loss, or corruption
                                during and after the lifecycle of our engagement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Cta/>
            <Footer />
        </>
    )
}

export default Contact;
